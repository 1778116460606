.pga-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.pga-container .overflow-y-scroll {
  flex: 1;
  overflow-y: auto;
}

.pga-container .max-h-full {
  max-height: calc(100vh - 9rem);
}
