/* Mobile View */
@media (max-width: 430px) {
  .Navbar {
    position: sticky;
    top: 0;
    z-index: 20;
  }

  .TrendingTicker {
    position: sticky;
    top: 50px;
    z-index: 10;
  }
}


/* Large Mobile View */
@media (max-width: 600px) {
  .Navbar {
    position: sticky;
    top: 0;
    z-index: 20;
  }

  .TrendingTicker {
    position: sticky;
    top: 50px;
    z-index: 10;
  }
}

