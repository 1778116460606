body{
  overflow-x: hidden;
}
/* Mobile View */
@media (max-width: 430px) {
  .Navbar {
    position: sticky;
    top: 0;
    z-index: 20;
  }

  .TrendingTicker {
    position: sticky;
    top: 50px;
    z-index: 10;
  }
}

@media (max-width: 575px) {
  .header-logo-wrapper img{
    width: 10rem;
    height: 100%;
  }
  .league-title-bg{
    margin-left: 10px;
  }
  .matchup-card{
    padding: 6px;
  }
  .propbets-page-tricker-div .dropdown > button{
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
  }
  .player-profile-td{
    min-width: 180px;
  }
  .sports-container {
    padding: 1rem;
  }
  .centered-content{
    height: 100% !important;
  }
  .modal-logo-wrapper .modal-logo-img{
    width: 200px;
    display: flex;
  }
  .player-info-page-wrapper{
    flex-direction: column;
    margin-left: 0;
    padding: 0 15px;
  }
  .PlayersStats{
    margin-top: 40px;
  }
  .game-schedule-box-display-wrapper{
    overflow-x: auto;
  }
}


/* Large Mobile View */
@media (max-width: 600px) {
  .Navbar {
    position: sticky;
    top: 0;
    z-index: 20;
  }

  .TrendingTicker {
    position: sticky;
    top: 50px;
    z-index: 10;
  }
}

.propbets-page-tricker-div{
  z-index: 12;  
}
.logo-image-wrapper{
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}