.follow-us-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.social-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 4px;
}

.social-icon img {
  width: 16px;
  height: 16px;
}

.social-icon.facebook { background-color: #4267B2; }
.social-icon.instagram { background-color: #E1306C; }
.social-icon.twitter { background-color: #1DA1F2; }
.social-icon.linkedin { background-color: #0077B5; }
.social-icon.tiktok { background-color: #010101; }