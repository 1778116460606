.dark-mode-toggle-label {
  width: 60px;
  height: 30px;
  position: fixed;
  bottom: 50px;
  right: 25px;
  display: block;
  background: #ebebeb;
  border-radius: 30px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: background 0.3s;
  /* transition: background 0.3s, transform 0.5s ease-in-out; */
  z-index: 1000;
}

.dark-mode-toggle-label:after {
  content: "";
  width: 26px;
  height: 26px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 50%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, background 0.3s;
}

#darkmode-toggle {
  width: 0;
  height: 0;
  visibility: hidden;
}

#darkmode-toggle:checked + .dark-mode-toggle-label {
  background: #242424;
}

#darkmode-toggle:checked + .dark-mode-toggle-label:after {
  transform: translateX(30px);
  background: linear-gradient(180deg, #777, #3a3a3a);
}

.sun-icon, .moon-icon {
  position: absolute;
  width: 20px;
  top: 5px;
  transition: fill 0.3s;
}

.sun-icon {
  left: 5px;
  fill: #fff;
}

.moon-icon {
  right: 5px;
  fill: #7e7e7e;
}

#darkmode-toggle:checked + .dark-mode-toggle-label .sun-icon {
  fill: #7e7e7e;
}

#darkmode-toggle:checked + .dark-mode-toggle-label .moon-icon {
  fill: #fff;
}

/* Hide the dark mode toggle on the sports page */
body.sports-page .dark-mode-toggle-label {
  display: none;
}

@media (min-width: 601px) {
  .dark-mode-toggle-label {
    width: 60px;
    height: 30px;
    bottom: 120px;
    right: 100px;
    /* bottom: 752px;
    left: 220px; */
  }
}

/* Sidebar hover proxy to handle hover effect */
/* .sidebar-hover-proxy {
  position: fixed;
  top: 0;
  left: 0;
  width: 80px; 
  height: 100%;
  transition: width 0.5s ease-in-out;
  z-index: 1;
  pointer-events: none;
}

.sidebar-hover-proxy:hover {
  width: 64px;
}

.sidebar-hover-proxy:hover ~ .dark-mode-toggle-label {
  transform: translateX(44px);
} */
