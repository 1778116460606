/* .ufc-container {
  width: 100%;
  padding: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.ufc-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.ufc-fighter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ufc-fighter-name {
  background: black;
  color: white;
  font-weight: bold;
  font-size: 30px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ufc-date {
  background: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 90%;
}

.ufc-odds-section {
  width: 100%;
  margin-bottom: 20px;
}

.ufc-odds-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ufc-odds-title-right {
  text-align: right;
}

.ufc-odds-title-centered {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ufc-odds-section h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.ufc-odds {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
}

.ufc-odds-tables {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.ufc-odds-table-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ufc-odds-table-wrapper-centered {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ufc-odds-table {
  border-collapse: collapse;
  margin-bottom: 20px;
  flex: 1;
  margin: 0 10px;
}

.ufc-odds-table th, .ufc-odds-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.ufc-odds-table th {
  background-color: #f2f2f2;
}

.ufc-show-more {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}


.ufc-show-more:hover {
  background: #0056b3;
}

.vsImg {
  width: 4rem;
  height: 4rem;
}



@media (max-width: 600px) {
  .vsImg {
    width: 3rem;
    height: 3rem;
  }

  .ufc-fighter-name {
    background: black;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px;
  }

  .ufc-date {
    background: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 15px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
} */


.ufc-container {
  width: 100%;
  padding: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.ufc-container.dark-mode {
  background: #1a1a1a;
  border: 1px solid #444;
}

.ufc-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.ufc-fighter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ufc-fighter-name {
  background: black;
  color: white;
  font-weight: bold;
  font-size: 30px;
  padding: 10px 20px;
  border-radius: 10px;
}

.ufc-fighter-name.dark-mode {
  background: #444;
}

.ufc-date {
  background: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 90%;
}

.ufc-date.dark-mode {
  background: #555;
}

.ufc-odds-section {
  width: 100%;
  margin-bottom: 20px;
}

.ufc-odds-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ufc-odds-title-right {
  text-align: right;
}

.ufc-odds-title-centered {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ufc-odds-section h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.ufc-odds {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
}
.ufc-odds span {
  color: black;
}

.ufc-odds-table thead{
  color: black;
}

.gtd {
  color: black;
}

.ufc-odds.dark-mode {
  border: 1px solid #444;
  background: #2a2a2a;
}

.ufc-odds-tables {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.ufc-odds-table-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ufc-odds-table-wrapper-centered {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ufc-odds-table {
  border-collapse: collapse;
  margin-bottom: 20px;
  flex: 1;
  margin: 0 10px;
}

.ufc-odds-table th, .ufc-odds-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.ufc-odds-table th {
  background-color: #f2f2f2;
}

.ufc-odds-table.dark-mode th, .ufc-odds-table.dark-mode td {
  border: 1px solid #444;
}

.ufc-odds-table.dark-mode th {
  background-color: #444;
}

.ufc-show-more {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.ufc-show-more:hover {
  background: #0056b3;
}

.vsImg {
  width: 4rem;
  height: 4rem;
}

@media (max-width: 600px) {
  .vsImg {
    width: 3rem;
    height: 3rem;
  }

  .ufc-fighter-name {
    background: black;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px;
  }

  .ufc-date {
    background: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 15px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
