.Sidebar {
  background-color: #202020;
}

.Navbar {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  margin-bottom: 30px;
  /* border-bottom: none; */
}


.Navbar::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  height: 3px;
  background-color: #dedfe4;
  z-index: -1;
}

.TrendingTicker {
  position: sticky;
  z-index: 10;
  background-color: inherit;
  top: 125px;
}

.TrendingTickerContent {
  overflow-x: auto;
  white-space: nowrap; 
  scrollbar-width: thin; 
  scrollbar-color: #ccc #fff;
}

.TrendingTicker::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 2px;
  background-color: #dedfe4;
  z-index: -1;
}

.image-container {
  width: 56px;
  height: 56px; 
  overflow: hidden;
  border-radius: 50%;
}

.Ellipse {
  width: 100%;
  height: 100%;
  object-fit: contain; 
}

.matchup-team {
  word-break: keep-all;
}

.matchup-team-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: normal;
}

.matchup-card {
  padding: 10px;
  min-height: 100px;
  background-color: #f9f9f9; 
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkbox-wrapper {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.custom-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid gray;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
}

.custom-checkbox.checked {
  background-color: #E0F64B;
  border: 1px solid #E0F64B;
  background-image: '../../assets/icons/checkMark.png';
  background-repeat: no-repeat;
  background-position: center;
}


.Profile button {
  margin-right: 30px;
  margin-top: 12px;
}

.Login button{
  margin-right: 35px;
  /* margin-top: 12px; */
  padding: 0 16px;
}

.SignUpBtn {
  margin-right: 20px;
}

.TryFreeButton {
  margin-right: 15px;
  margin-top: 10px;
}

.Backbutton {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
}

.Backbutton button {
  width: 3rem;
  height: 3rem;
}

.odds-column {
  padding-left: 10px;
}

.total-row {
  padding-left: 40px;
}

.total-price {
  padding-left: 60px;
}

.SearchField {
  flex: 1;
  max-width: 400px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
}

.SearchIcon {
  width: 20px;
  height: 20px;
}

.search-dropdown {
  left: 175px;
}

.profile-dropdown {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 90;
}

.support-dropdown {
  position: absolute;
  top: calc(100% + 80px);
  left: -35px; 
  width: 100px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 90;
}

@keyframes bg {
  0% {
    background: #fff;
  }
  100% {
    background: #E0F64E;
  }
}

.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.loading div {
  position: absolute;
  background: #E0F64E;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div:nth-child(2) {
  animation-delay: -.7s;
}

.dropdown > button {
  background-color: white;
}

.dropdown-menu li span {
  background-color: white;
  transition: background-color 0.3s ease;
}

.dropdown-menu li span:hover {
  background-color: #e0f64b;
}

.league-title-bg {
  background-color: #e0f64b; 
  margin-left: 20px;
}

@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}


/* Small Mobile View */
@media (max-width: 430px) {
  .Navbar {
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  .Navbar::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    height: 3px;
    background-color: #dedfe4;
    z-index: -1;
  }

  .OptionsButton {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
  }

  .OptionsButton img {
    width: 40px;
    height: 40px;
    display: block;
  }

  .MobilePopup {
    z-index: 80;
  }

  .SearchField {
    display: none;
  }

  .Backbutton {
    display: none;
  }

  .LogoHorWhiteColor {
    width: 13rem;
    height: auto;
    margin-left: -20px;
    margin-right: 0;
  }

  .TryFreeButton {
    width: 5rem;
    height: 30px;
    font-size: 0.65rem;
    padding: 0rem 0.5rem;
    margin-right: 10px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .TryFreeButton span {
    font-size: 0.65rem;
    font-weight: bolder;
  }

  .SignUpBtn {
    width: 64px;
    height: 30px;
    padding: 0rem 0.5rem;
    margin-right: 10px;
  }

  .SignUpBtn span {
    font-size: 0.75rem;
    font-weight: bold;
  }

  .Login button{
    width: 64px;
    height: 30px;
    padding: 0rem 0.5rem;
    margin-right: 10px;
  }

  .Login span{
    font-size: 0.9rem;
    font-weight: bold;
  }

  .TrendingTicker {
    top: 60px !important;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 1rem;
    z-index: 5;
  }

  .modal-open .TrendingTicker {
    z-index: 0;
  }
  
  .flex-grow {
    flex-grow: 0;
  }


  .relative {
    margin-top: 0;
  }

  .Profile {
    margin-left: 0;
  }

  .Profile button {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: 10px;
  }

  .Profile button img {
    width: 20px;
    height: 20px;
  }

  .support-dropdown {
    left: -105px; 
    top: calc(100% + 55px);
    width: 100px;
  }

  .profile-dropdown {
    left: -5px;
    width: 120px;
    box-sizing: border-box;
  }

  .profile-dropdown button {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 0.4rem;
  }

  .profile-options {
    font-size: 0.4rem;
    width: 110px;
    padding: 8px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
  

  button {
    width: auto;
  }

  .sports-buttons {
    flex-direction: row;
    justify-content: space-between;
  }

  .sports-button {
    flex: 1;
    padding: 0.2rem 0.2rem;
    font-size: 0.7rem;
    margin: 0 0.1rem;
  }

  .click-game-info {
    font-size: 0.7rem;
    margin-left: 0;
  }

  .date-span {
    font-size: 0.6rem;
    z-index: 40;
  }

  .matchup-date {
    font-size: 0.6rem;
  }

  .matchup-team {
    font-size: 0.7rem;
  }

  .matchup-odds {
    font-size: 0.6rem;
  }

  .matchup-card {
    padding: 10px;
  }

  .footer {
    font-size: 0.6rem;
    /* padding-bottom: 0px; */
  }

  .col-span-2 {
    flex: 1 1 0%;
    min-width: 0;
  }

  .ml-2 {
    margin-left: 0.5rem;
  }

  .space-x-3 > *:not(:last-child) {
    margin-right: 0.75rem;
  }

  .space-x-3 {
    display: flex;
    flex-wrap: nowrap;
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .justify-around {
    justify-content: space-around;
  }

  .justify-center {
    justify-content: center;
  }

  .upcoming-odds-header {
    position: relative;
    z-index: 0;
  }

  .odds-column {
    padding-left: 40px;
  }

  .odds-column span {
    font-size: 0.6rem;
  }

  .matchup-team-container span {
    font-size: 0.6rem;
  }
  
  .odds-column .total-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .odds-column .total-row span {
    font-size: 0.6rem;
  }

  .odds-column {
    padding-left: 45px;
  }
  
  .total-row {
    padding-left: 0;
  }
  
  .total-price {
    padding-left: 20px;
  }

  .spreadPoints {
    margin-right: 0 !important;
    margin-left: 10px;
  }
  
}

@media (min-width: 601px) {
  .LogoHorWhiteColor {
    width: 30rem;
    height: auto;
    margin-top: 0;
    margin-right: 10rem;
  }

  .Profile {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .Login {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .TryFreeButton {
    margin-right: 15px;
  }
}


/* Large Mobile View */
@media (min-width: 431px) and (max-width: 600px) {
  .Navbar {
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: inherit;
    padding-top: 0;
    margin-bottom: 0;
    height: 80px;
  }

  .Navbar::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: #dedfe4;
    z-index: -1;
  }

  .OptionsButton {
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
  }

  .OptionsButton img {
    width: 40px;
    height: 40px;
    display: block;
  }

  .MobilePopup {
    z-index: 80;
  }

  .SearchField {
    display: none;
  }

  .Backbutton {
    display: none;
  }

  .LogoHorWhiteColor {
    width: 17rem;
    height: auto;
  }

  .TryFreeButton {
    width: 70px;
    font-size: 0.6rem;
    padding: 0rem 0.5rem;
    margin-right: 15px;
  }

  .TryFreeButton span {
    font-size: 0.6rem;
    font-weight: bold;
  }

  .SignUpBtn {
    width: 64px;
    height: 30px;
    padding: 0rem 0.5rem;
    margin-right: 10px;
  }

  .SignUpBtn span {
    font-size: 0.75rem;
    font-weight: bold;
  }

  .Login button{
    width: 64px;
    height: 30px;
    padding: 0rem 0.5rem;
    margin-right: 10px;
  }

  .Login span{
    font-size: 0.9rem;
    font-weight: bold;
  }

  .TrendingTicker {
    top: 80px !important;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 1rem;
    z-index: 5;
  }

  .modal-open .TrendingTicker {
    /* display: none; */
    z-index: 0;
  }

  .flex-grow {
    flex-grow: 0;
  }

  .relative {
    margin-top: 0;
  }

  .Profile {
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
  }

  .support-dropdown {
    left: -100px; 
    width: 100px;
  }

  button {
    width: auto;
  }

  .sports-buttons {
    flex-direction: row;
    justify-content: space-between;
  }

  .sports-button {
    flex: 1;
    padding: 0.2rem 0.2rem;
    font-size: 0.7rem;
    margin: 0 0.1rem;
  }

  .click-game-info {
    font-size: 0.7rem;
    margin-left: 0;
  }

  .date-span {
    font-size: 0.6rem;
    z-index: 40;
  }

  .matchup-date {
    font-size: 0.6rem;
  }

  .matchup-team {
    font-size: 0.7rem;
  }

  .matchup-odds {
    font-size: 0.6rem;
  }

  .matchup-card {
    padding: 8px;
    min-height: 120px;
  }

  .footer {
    font-size: 0.6rem;
    /* padding-bottom: 0px; */
  }

  .col-span-2 {
    flex: 1 1 0%;
    min-width: 0;
  }

  .ml-2 {
    margin-left: 0.5rem;
  }

  .space-x-3 > *:not(:last-child) {
    margin-right: 0.75rem;
  }

  .space-x-3 {
    display: flex;
    flex-wrap: nowrap;
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .justify-around {
    justify-content: space-around;
  }

  .justify-center {
    justify-content: center;
  }

  .upcoming-odds-header {
    position: relative;
    z-index: 0;
  }

  .odds-column {
    padding-left: 45px;
  }

  .spreadPoints {
    margin-right: 0 !important;
    margin-left: 30px;
  }
  
  .total-row {
    padding-left: 10px;
  }

  .total-price {
    padding-left: 20px;
    margin-right: 0 !important; 
  }

}
