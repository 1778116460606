

/* Small Mobile View */
@media (max-width: 430px) {

  .TrendingTicker .dropdown > button {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
  }

  .TrendingTicker .dropdown-menu li span {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
  }

  .TrendingTicker .dropdown:last-child {
    margin-left: 0;
  }

  .modal-open .TrendingTicker {
    z-index: 0;
  } 

  .sports-buttons {
    flex-direction: row;
    justify-content: space-between;
  }

  .sports-button {
    flex: 1;
    padding: 0.2rem 0.2rem;
    font-size: 0.7rem;
    margin: 0 0.1rem;
  }

  .click-game-info {
    font-size: 0.7rem;
    margin-left: 0;
  }

  .date-span {
    font-size: 0.5rem;
    z-index: 40;
  }

  .matchup-date {
    font-size: 0.6rem;
  }

  .matchup-team {
    font-size: 0.7rem;
  }

  .matchup-odds {
    font-size: 0.6rem;
  }

  .col-span-1 {
    font-size: 0.6rem;
  }

  .footer {
    font-size: 0.6rem;
    padding-bottom: 20px;
    /* padding-bottom: 60px; */
  }

  .col-span-2 {
    flex: 1 1 0%;
    min-width: 0;
  }

  .ml-2 {
    margin-left: 0.5rem;
  }

  .space-x-3 > *:not(:last-child) {
    margin-right: 0.75rem;
  }

  .space-x-3 {
    display: flex;
    flex-wrap: nowrap;
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .justify-around {
    justify-content: space-around;
  }

  .justify-center {
    justify-content: center;
  }
  
  .dropdown-menu {
    z-index: 50;
  }
}


/* Large Mobile View */
@media (min-width: 431px) and (max-width: 600px) {

  /* .TrendingTicker .dropdown {
    position: relative;
    z-index: 60;
  }*/

  .TrendingTicker .dropdown > button {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
  } 


  .TrendingTicker .dropdown-menu li span {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
    z-index: 60;
  }

  /* .TrendingTicker .dropdown:last-child {
    margin-left: 0;
    z-index: 40;
  }
  */

  .modal-open .TrendingTicker {
    z-index: 0;
  } 

  .sports-buttons {
    flex-direction: row;
    justify-content: space-between;
  }

  .sports-button {
    flex: 1;
    padding: 0.2rem 0.2rem;
    font-size: 0.7rem;
    margin: 0 0.1rem;
  }

  .click-game-info {
    font-size: 0.7rem;
    margin-left: 0;
  }

  .date-span {
    font-size: 0.5rem;
    /* z-index: 50; */
  }

  .matchup-date {
    font-size: 0.6rem;
  }

  .matchup-team {
    font-size: 0.7rem;
  }

  .matchup-odds {
    font-size: 0.6rem;
  }

  .col-span-1 {
    font-size: 0.6rem;
  }

  .footer {
    font-size: 0.6rem;
    padding-bottom: 20px;
  }

  .col-span-2 {
    flex: 1 1 0%;
    min-width: 0;
  }

  .ml-2 {
    margin-left: 0.5rem;
  }

  .space-x-3 > *:not(:last-child) {
    margin-right: 0.75rem;
    /* z-index: 20; */
  }

  .space-x-3 {
    display: flex;
    flex-wrap: nowrap;
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .justify-around {
    justify-content: space-around;
  }

  .justify-center {
    justify-content: center;
  }

  .TrendingTicker .dropdown-menu {
    z-index: 70 !important;
  }
}

.dropdown-menu {
  z-index: 50 !important; 
}