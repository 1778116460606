.footer {
  padding: 20px 0;
  text-align: center;
}

.footer.light {
  background-color: #f8f9fa;
  color: #6c757d;
  border-top: 1px solid #e9ecef;
}

.footer.dark {
  background-color: #343a40;
  color: #adb5bd;
  border-top: 1px solid #495057;
}

@media (max-width: 768px) {
  .footer {
    padding: 10px 0;
    text-align: left;
    padding-left: 10px;
  }
}
