.backLogo {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  width: 100%;
}

.sports-page-back-button {
  position: absolute;
  left: 0;
}

.logo-container {
  margin: 0 auto;
}

.logo {
  width: 30rem;
  margin-top: 0;
}

.sports-page-back-button button {
  width: 3rem !important;
  height: 3rem !important;
}

.sports-page-back-button img {
  width: 100% !important;
  height: 100% !important;
}

.centered-content {
  width: 60% !important;
  height: 80% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Small Mobile View */
@media (max-width: 430px) {
  .sports-container {
    padding: 2rem;
  }

  .sport-card {
    min-width: 3.5rem !important;
    max-width: 6rem !important;
    padding: 0.5rem;
  }

  .sport-card img {
    height: 2.5em;
  }

  .sport-card span {
    font-size: 0.775rem;
  }

  .background-container {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .logo {
    width: 17rem;
    margin-top: 1rem;
  }

  .centered-content {
    width: 90% !important;
    height: 70% !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .sports-page-back-button button {
    width: 1rem !important;
    height: 1rem !important;
  }

  .sports-page-back-button img {
    width: 1rem !important;
    height: 1rem !important;
  }
}

/* Large Mobile View */
@media (min-width: 431px) and (max-width: 600px) {
  .sports-container {
    padding: 2rem;
  }

  .sport-card {
    min-width: 5rem !important;
    padding: 0.5rem;
  }

  .sport-card img {
    height: 4rem;
  }

  .sport-card span {
    font-size: 1rem;
  }

  .background-container {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .logo {
    width: 26rem;
    margin-top: 1rem;
  }

  .centered-content {
    width: 90% !important;
    height: 80% !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .sports-page-back-button button {
    width: 2rem !important;
    height: 2rem !important;
  }

  .sports-page-back-button img {
    width: 2rem !important;
    height: 2rem !important;
  }
}


@media (min-width: 601px){
  .backLogo {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    width: 100%;
  }

  .sports-container {
    padding: 2rem;
  }

  .sport-card {
    min-width: 8rem !important;
    padding: 0.5rem;
  }

  .sport-card img {
    height: 4rem;
  }

  .sport-card span {
    font-size: 1rem;
  }

  .sports-page-back-button {
    position: absolute;
    left: 0;
  }

  .background-container {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .logo {
    width: 30rem;
    margin-top: 1rem;
  }

  .centered-content {
    width: 50% !important;
    height: 80% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sports-page-back-button button {
    width: 3rem !important;
    height: 3rem !important;
  }
  
  .sports-page-back-button img {
    width: 100% !important;
    height: 100% !important;
  }

  .logo-container {
    margin: 0 auto;
  }
  
}