table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  border-bottom: 2px solid #ccc;
}

td {
  border: none;
}

.odds-box {
  padding: 8px;
  margin: 4px 0;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.dark th {
  background-color: #333;
}

.dark td {
  background-color: #222;
}

/* .flex.items-center img {
  height: 24px;
  width: 24px;
  margin: 0 8px;
} */
